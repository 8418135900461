import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    columns: [
      {
        id: 1,
        title: "Geplant",
        tasks: [
          {
            id: 1,
            title: "Ticket 01",
            date: "Sep 14",
            type: "Feature Request"
          },
          {
            id: 2,
            title: "Ticket 02",
            date: "Sep 12"
          }
        ]
      },
      {
        id: 2,
        title: "In Bearbeitung",
        tasks: [
          {
            id: 6,
            title: "Ticket 03",
            date: "Sep 9",
            type: "Design"
          },
          {
            id: 7,
            title: "Ticket 04",
            date: "Sep 14",
            type: "Feature Request"
          },
          {
            id: 8,
            title: "Ticket 05",
            date: "Sep 12",
            type: "Backend"
          }
        ]
      },
      {
        id: 3,
        title: "Auf Kundenantwort warten",
        tasks: [
          {
            id: 9,
            title: "Ticket 06",
            date: "Sep 12"
          },
          {
            id: 10,
            title: "Ticket 07",
            date: "Sep 9",
            type: "Design"
          }
        ]
      },
      {
        id: 4,
        title: "Abgeschlossen",
        tasks: [
          {
            id: 14,
            title: "Ticket 08",
            date: "Sep 14",
            type: "Feature Request"
          }
        ]
      }
    ]
  },
  mutations: {
    addTask(state, task) {
      state.columns[task.column - 1].tasks.push({
        id: task.id,
        title: task.title,
        date: "null",
        type: "null"
      });
    },
    deleteTask(state, id) {
      const index = state.columns.forEach(column => {
        const index = column.tasks.findIndex(task => task.id == id);
        console.log("index: ", index);
        if (index >= 0) {
          console.log("inside: ", column.tasks[index]);
          column.tasks.splice(index, 1);
        }
      });
    }
  },
  actions: {
    addTask(context, task) {
      context.commit("addTask", task);
    }
  },
  modules: {}
});
