<template>
  <div class="NavBarLeftSide">
    <v-navigation-drawer v-model="drawer" app permanent expand-on-hover>
      <!-- Nav header !-->
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>John Doe</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <!-- navigation buttons !-->
      <v-list dense rounded>
        <v-list-item
          class="px-2"
          v-for="navItem in navItems"
          :key="navItem.title"
          link
          :to="navItem.path"
        >
          <v-list-item-icon>
            <v-icon>{{ navItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ navItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- settings button -->
      <v-divider></v-divider>
      <v-list dense rounded>
        <v-list-item class="px-2" @click="settingsDrawer = true">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="settingsDrawer" fixed temporary left>
      <v-list nav dense>
        <v-list-item-group
          v-model="settingsGroup"
          active-class="teal--text text--accent-4"
        >
          <!-- title -->
          <v-row>
            <v-col cols="8"> <h3>Settings</h3> </v-col>
            <v-col cols="4" align="right">
              <v-btn icon @click.stop="settingsDrawer = !settingsDrawer">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- dark mode toggle -->
          <v-switch
            v-model="this.$vuetify.theme.dark"
            id="dark-mode-switch"
            @click="toggleDarkMode"
            inset
            label="dark mode"
            persistent-hint
          ></v-switch>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    settingsDrawer: false,
    settingsGroup: null,
    drawer: true,
    navItems: [
      { title: "Home", icon: "mdi-home-city", path: "/" },
      { title: "Dashboard", icon: "mdi-account", path: "/todo" },
      { title: "Pendenzen", icon: "mdi-account-group-outline", path: "/todo" },
      { title: "Software", icon: "mdi-account-group-outline", path: "/todo" },
      { title: "BAS", icon: "mdi-account-group-outline", path: "/todo" },
      { title: "ToDo", icon: "mdi-clipboard-list-outline", path: "/todo" },
      { title: "Kalender", icon: "mdi-account-group-outline", path: "/todo" }
    ]
  }),
  methods: {
    toggleDarkMode: function() {
      console.log(this);
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");

    if (theme) {
      theme === "true"
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  }
};
</script>
