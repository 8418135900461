






































































import Vue from "vue";

export default Vue.extend({
  name: "NavBarBottom",
  data() {
    return {
      navItems: [
        { title: "Home", icon: "mdi-home", path: "/" },
        { title: "About", icon: "mdi-home", path: "/about" },
        { title: "Photos", icon: "mdi-image-frame", path: "/photos" }
      ],
      active: true,
      drawer: false,
      group: null,
      sheet: false,
      tiles: [
        { img: "keep.png", title: "LandingPage 01", path: "/landingpage-01" },
        { img: "inbox.png", title: "LandingPage 02", path: "/landingpage-02" },
        { img: "hangouts.png", title: "Todo", path: "/todo" },
        { img: "messenger.png", title: "Item 04", path: "/b" },
        { img: "google.png", title: "Item 05", path: "/c" }
      ]
    };
  },
  methods: {
    toggleDarkMode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");

    if (theme) {
      theme === "true"
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  }
});
