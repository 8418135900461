import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
      /*TOOD: improve theme cache themeCache: {
        get: key => localStorage.getItem(key.toString()),
        set: (key, value) => localStorage.setItem(key.toString(), value)
      }*/
    },
    themes: {
      light: {
        primary: "#ee44aa",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
